<template>
	<div class="login-page">
		<div id="particles"></div>
		<div class="login-form">
			<div class="login-title" >
			 <div class="title">沈阳欧米奇后台系统</div>
			 <el-divider></el-divider>
			 <p class="">梦想开始的地方</p>
			 <p class="">与你携手共进</p>
			</div>
			
			 <el-form :model="loginForm" class="login-elform"  ref="loginForm" label-position="left" label-width="0px">
			 	<el-form-item prop="username" required>
			 		<el-input  v-model="loginForm.username" prefix-icon="el-icon-user-solid" placeholder="登录账号"></el-input>
			 	</el-form-item>
			 	<el-form-item prop="password" required>
			 		<el-input v-model="loginForm.password" type="password" prefix-icon="el-icon-question" placeholder="登录密码"></el-input>
			 	</el-form-item>
			 	
			 	<el-form-item >
			 		
			        <el-button type="primary" style="width:100%;"  @click.native.prevent="test()"  >登 录</el-button>
			    </el-form-item>
			 </el-form>
			
			
			
		</div>
	</div>
</template>

<script>
    import axios from 'axios'
	const baseurl = process.env.VUE_APP_BASE_API
    axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
	export default{
		metaInfo: {
			title: '沈阳欧米奇后台登录页面', // set a title
			meta: [{                 // set meta
				name: 'keywords',
				content: '沈阳欧米奇'
			},{                 // set meta
				name: 'description',
				content: '沈阳欧米奇'
			}]
		},
		data(){
			return{
				loginForm:{
					username:"",
					password:"",
				},
				redirect: undefined
			}
		},
		mounted: function() {
			//particlesJS('particles',particlesJson);
		},
		created(){
			//_self = this;
			//_self.refreshCaptcha()
		},
		methods:{
			test(){
				if(!this.loginForm.username)
				{
					this.$message({
						showClose: true,
						message: '用户名不能为空！',
						type: 'error'
					});
				}
				if(!this.loginForm.password)
				{
					this.$message({
						showClose: true,
						message: '密码不能为空！',
						type: 'error'
					});
				}
				else{
					axios({
						method: 'post',
						url: `${baseurl}/api/login`,
						data: {
							"username":this.loginForm.username,
							"password":this.loginForm.password
						}
					}).then(res => {
						//let res_json = JSON.parse(res);
						
						if(res.data.code==2000)
						{
							localStorage.setItem('islogin','islogin');
							this.$router.push('/index/index');
						}
						else{
							this.$message({
								showClose: true,
								message: res.data.msg,
								type: 'error'
							});
						}
					}).catch(res => {
						console.log(res);
						this.$message({
								showClose: true,
								message: '跨域了',
								type: 'error'
						});
					});
				} 
            }
		}
	}
</script>

<style lang="scss">
	.login-page{
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: url(../assets/img/login-bj.jpg);
		background-size: 100% 100%;
		#particles{
			position: absolute;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;
			z-index: 2;
		}
		.login-form{
	       position: absolute;
	       width: 100%;
	       height: 100%;
	       z-index: 3;
	       display: flex;
	       align-items: center;
	       justify-content: center;
		   .login-elform{
		   	width: 300px;
		   	padding: 20px;
		   	background:rgba(0, 0,0,0.5);
		   	border-radius: 5px;
		   }
		   .login-title{
		   	width: 200px;
		   	height: 150px;
		   	background:#007aff;
		   	padding: 30px;
		   	border-radius: 5px 0 0 5px;
		   	
		   }
		   .login-title p{
		   	color: #FFFFFF;
		   }
		   .title{
		   	font-size: 20px;
		   	color: #fff;
		   }
	    }
	}
</style>
